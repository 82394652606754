import _debounce from "lodash/debounce";
import crypto from '@/mixins/crypto';
import isMobilePhone from 'validator/lib/isMobilePhone';
export default {
  name: 'Login',
  mixins: [crypto],
  components: {},
  data() {
    return {
      phone: '',
      password: ''
    };
  },
  props: {},
  computed: {},
  methods: {
    validatePhone() {
      // 验证手机号
      if (this.phone === '' || !isMobilePhone(this.phone, 'zh-CN')) {
        let msg = this.phone === '' ? '请输入手机号!' : '手机号格式有误!';
        this.$toast(msg);
        return false;
      } else {
        return true;
      }
    },
    validatePassword() {
      // 验证密码
      // let pattern = /^(?=.*\d)(?=.*[a-zA-Z]).{8,16}$/
      // if (this.password === '' || !pattern.test(this.password)) {
      if (this.password === '') {
        // let msg = this.password === '' ? '请输入密码!' : '用户密码不正确!'
        let msg = '请输入密码!';
        this.$toast(msg);
        return false;
      } else {
        return true;
      }
    },
    handleLogin: _debounce(function () {
      // 登录，提交，防抖
      if (this.validatePhone() && this.validatePassword()) {
        // let password = this.aesEncrypt(this.password)
        let {
          phone,
          password
        } = this;
        this.$store.dispatch('user/HandleLogin', {
          phone,
          password
        }).then(response => {
          let result = response.data;
          if (result.code === '10000') {
            let {
              redirect
            } = this.$route.query;
            if (redirect && redirect !== '/404') {
              // 判断是否存在要进入的页面
              this.$router.replace({
                path: redirect
              });
            } else {
              this.$router.replace({
                path: '/'
              });
            }
            // this.$router.go(-1)
          }
        }).catch(error => {
          this.$toast(error.message);
        });
      }
    }, 300)
  },
  watch: {},
  created() {},
  mounted() {},
  beforeRouteEnter(to, from, next) {
    // console.log(to)
    // console.log(from)
    next();
  },
  beforeRouteUpdate(to, from, next) {
    // console.log(to)
    // console.log(from)
    next();
  }
};